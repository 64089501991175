import React from 'react';
import Container from 'react-bootstrap/Container';

import './index.css';

const About = () => {
  return (
    <Container className="page-container">
      <div className="about-intro">
        <h1 className="about-intro-title text-center text-md-start">About Me</h1>
        <div>
          <p className="about-intro-text">
            I started off my career as a UX developer - in college I enjoyed my web design and development class, but couldn't quite put my finger on why. Throughout my time as a developer, I learned that programming wasn't quite my passion and wasn't sure where to turn next. I decided to move to quality assurance, and this was a better fit for me, but I still felt like it wasn't what I was itching to do. I was more interested in the experience itself, coordinating with the PM and reviewing designs. I wanted to make sure the user had an exceptional experience, that the flows made sense, and that our products were providing VALUE and EASE to the user. It dawned on me that what I loved was a combination of my career trajectories, and I hope to use that to propel myself forward into UX.
          </p>
        </div>
      </div>
      <div className="resume-intro">
        <h2 className="resume-title">Professional Resume</h2>
        <div className="resume-subtitle">Relevant Experience</div>
      </div>
      <div className="experience-grid">
        <div className="experience-block mb-3">
          <div className="experience-title">Quality Engineer III - Calendly</div>
          <div className="experience-subtitle">Nov 2021 - Present</div>
          <div className="experience">
            <ul>
              <li >
                Designed course content and structure for our QA boot camp; created surveys for insights on how to improve upon course
              </li>
              <li>
                Developed manual accessibility guidelines and strategy for QA org
              </li>
              <li>
                Shadowing and attending usability interviews while assisting with note taking to gather insights and themes
              </li>
              <li>
                Currently in early stage process of researching and conducting comparative analysis on multiple test case management tools to better serve our QA team’s needs
              </li>
            </ul>
          </div>
        </div>
        <div className="experience-block mb-3">
          <div className="experience-title">Senior QA Engineer - Indeed</div>
          <div className="experience-subtitle">Oct 2017 - August 2021</div>
          <div className="experience">
            <ul>
              <li >
                Led quality assurance initiatives across our overarching team
              </li>
              <li>
                Curated and held QA fundamentals workshops; created surveys to gather insights and make changes based on feedback
              </li>
              <li>
                Oversaw and performed accessibility audits; created accessibility guidelines for engineers, UX and QA
              </li>
            </ul>
          </div>
        </div>
        <div className="experience-block mb-3">
          <div className="experience-title">UX Developer - General Motors</div>
          <div className="experience-subtitle">Jan 2015 - April 2017</div>
          <div className="experience">
            <ul>
              <li >
                Developed custom UI components using AngularJS
              </li>
              <li>
                Synced with designers to build prototypes using HTML and CSS according to redline mocks
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default About;
