import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Katie from '../../assets/katie.webp';

import './index.css';

const Home = () => {
 return (
   <Container className="page-container">
     <Row className="justify-content-md-center">
       <Col className="text-center text-md-end mb-2 mb-md-0" sm={12} md>
           <img alt="" className="image me-md-5" src={Katie} />
       </Col>
       <Col className="text-container" sm={12} md>
         <h1 className="title">
           Katie Griffiths
         </h1>
         <span className="description">
           I'm a Quality Assurance professional, and aspiring UX Designer and Researcher. I love to travel (lived in Japan!), go to the gym, play board games and eat desserts in my pajamas.
         </span>
         <Button as={Link} className="about-button" to="/about">
           About Me
         </Button>
       </Col>
     </Row>
   </Container>
 );
};

export default Home;
