import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Home from '../../../assets/home_PNG.webp';
import Details from '../../../assets/details_PNG.webp';
import Billing from '../../../assets/billing_PNG.webp';
import MonthlySub from '../../../assets/monthl sub_PNG.webp';
import Details2 from '../../../assets/details_PNG_2.webp';
import Cart from '../../../assets/cart_PNG.webp';
import BillingSm from '../../../assets/billing_PNG_sm.webp';
import Search from '../../../assets/search_PNG.webp';
import Refinement1 from '../../../assets/refinement_1.webp';
import Refinement2 from '../../../assets/refinement_2.webp';
import Refinement3 from '../../../assets/refinement_3.webp';
import Refinement4 from '../../../assets/refinement_4.webp';
import NextSteps1 from '../../../assets/next_steps_1.webp';
import NextSteps2 from '../../../assets/next_steps_2.webp';

import './index.css';

const GoogleUX = () => {
  return (
    <div className="page-container">
      <Container>
        <Row className="text-start" style={{ marginBottom: '60px', width: '100%' }}>
          <div className="col-lg-1" />
          <div className="col-lg-10">
            <h1 style={{ fontSize: '56px', fontWeight: 'bold' }}>Google UX Project</h1>
            <Col className="col-lg-6" style={{ fontSize: '16px' }}>
              User Interviews, User Personas, Competitive Analysis, Usability Testing, Affinity Mapping, Wireframes, Prototyping, Mockups
            </Col>
          </div>
        </Row>
      </Container>
      <Row style={{ marginBottom: '60px', width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>Research Background and Plan</h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row style={{ marginBottom: '60px' }}>
          <div className="col-lg-1" />
          <Col className="col-12 col-lg-3">
            <h3 style={{ fontSize: '32px', fontWeight: 'bold' }}>Discovery Research</h3>
            <div style={{ fontSize: '20px', lineHeight: '32px' }}>
              User Interviews, User Personas, Competitive Analysis
            </div>
          </Col>
          <Col className="col-12 col-lg-7">
            <div style={{ fontSize: '18px', lineHeight: '32px' }}>
              Stakeholders wanted to create an app specifically dedicated to cat owners, where users can find and place orders for their items, then have those items delivered to them.
            </div>
            <div style={{ fontSize: '18px', lineHeight: '32px' }}>
              Competitive analysis was performed against multiple apps to provide insights to features and functionality.
            </div>
            <div style={{ fontSize: '18px', lineHeight: '32px' }}>
              User interviews were conducted to determine pain points, use cases and desirability for a dedicated cat-focused app.
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-7">
            <h3 style={{ fontSize: '32px', fontWeight: 'bold' }}>Key Takeaways</h3>
            <ul style={{ marginBottom: 0 }}>
              <li style={{ fontSize: '16px', lineHeight: '32px' }}>
                Interviewees were receptive to the idea of a cat-focused app for finding and ordering food
              </li>
              <li style={{ fontSize: '16px', lineHeight: '32px' }}>
                Most, if not all, competitors were servicing multiple animals (pets) and not specifically cats
              </li>
              <li style={{ fontSize: '16px', lineHeight: '32px' }}>
                4 out of 5 interviewees went through a bag of cat food around the 1 month mark
              </li>
              <li style={{ fontSize: '16px', lineHeight: '32px' }}>
                Interviewees were interested in having monthly subscriptions for cat food delivery
              </li>
            </ul>
            </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-6">
                <h3 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: 0 }}>Usability Testing</h3>
                <h4>Research Methodology</h4>
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Unmoderated usability testing sessions</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>US, Remote</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>10 minutes per user suggested</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>5 individual cat owners</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Users were asked to interact with a low fidelity prototype</li>
                </ul>
              </Col>
              <Col className="col-12 col-lg-6">
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Is making a recurring order/subscription intuitive and simple for the user?</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Are there any areas of navigation or search that doesn’t make sense or prohibits the user from continuing?</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>How long does it take for a user to find their cat food?</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>How long does it take for a user to checkout?</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Row style={{ marginBottom: '60px', width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Prototype Example Pages Tested
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10 text-center">
            <img className="ms-lg-4 me-lg-4" src={Home} />
            <img className="ms-lg-4 me-lg-4" src={Details} />
            <img className="ms-lg-4 me-lg-4" src={Billing} />
          </Col>
        </Row>
      </Container>
      <Row style={{ width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Research Insights
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Row style={{ width: '100%', marginBottom: '40px' }}>
        <div style={{ background: 'rgb(232, 230, 230)', padding: '40px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>1</span>
                  <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    Users wanted more information on monthly subscriptions
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>2</span>
                  <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    A confirmation page is needed before checking out
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>3</span>
                  <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    Adding directly to cart from search is preferred
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>4</span>
                  <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    More entry points for item location on home page
                  </span>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-7">
                <h3 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '4px' }}>Creating a monthly subscription</h3>
                <ul style={{ marginBottom: '28px' }}>
                  <li style={{ fontSize: '18px', lineHeight: '32px', fontWeight: 'bold' }}>5 out of 5 participants were wanted more info on monthly subscriptions</li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>One user specified they wanted to manage and see their subscriptions and was annoyed they could not change it after the fact</li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>Participants generally understood what monthly subscriptions entail, but wanted more information</li>
                </ul>
                <div style={{ color: 'rgb(87, 61, 111)', lineHeight: '32px', fontWeight: 'bold', fontStyle: 'italic', fontSize: '18px' }}>
                  "I would want to know more about when that monthly delivery date might be"
                </div>
              </Col>
              <Col className="col-12 col-lg-5 d-flex align-items-end justify-content-center text-center">
                <img src={Details2} />
                <img src={MonthlySub} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-7">
                <h3 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '4px' }}>Completing the checkout process</h3>
                <ul style={{ marginBottom: '28px' }}>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>
                    All users were able to quickly checkout, <strong>but 2 out of 5 participants felt uneasy that there was no screen to review their purchase</strong>
                  </li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>Users wanted to be able to double-check their cart order and items before actually making a payment </li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>Not including an overview/review page before completing the purchase can reduce trustworthiness </li>
                </ul>
                <div style={{ color: 'rgb(87, 61, 111)', lineHeight: '32px', fontWeight: 'bold', fontStyle: 'italic', fontSize: '18px' }}>
                  "I get anxious when checking out so I want to see an overview of what I'm ordering on the payment page"
                </div>
              </Col>
              <Col className="col-12 col-lg-5 d-flex align-items-end justify-content-center text-center">
                <img src={Cart} />
                <img src={BillingSm} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-7">
                <h3 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '4px' }}>Adding items to the cart</h3>
                <ul style={{ marginBottom: '28px' }}>
                  <li style={{ fontSize: '18px', lineHeight: '32px', fontWeight: 'bold' }}>2 out of 5 participants wanted to be able to add directly from the search page</li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>Users felt adding items to the cart was generally easy, but wanted a faster way</li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>One user mentioned that it’s easier if they already know what they want since they wouldn’t need to view details</li>
                </ul>
                <div style={{ color: 'rgb(87, 61, 111)', lineHeight: '32px', fontWeight: 'bold', fontStyle: 'italic', fontSize: '18px' }}>
                  "If I know that [item] is all I want, I want to be able to add [to the cart] directly"
                </div>
              </Col>
              <Col className="col-12 col-lg-5 d-flex align-items-end justify-content-center text-center">
                <img src={Details2} />
                <img src={MonthlySub} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-7">
                <h3 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '4px' }}>Navigating and searching through the app</h3>
                <ul style={{ marginBottom: '28px' }}>
                  <li style={{ fontSize: '18px', lineHeight: '32px', fontWeight: 'bold' }}>5 out of 5 participants were able to quickly search for and find their cat food</li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>Most users used the search bar to find their items, but one user used the food tab</li>
                  <li style={{ fontSize: '18px', lineHeight: '32px' }}>One user brought up that they would like to have a “favorites” section on the home page to easily find their items </li>
                </ul>
                <div style={{ color: 'rgb(87, 61, 111)', lineHeight: '32px', fontWeight: 'bold', fontStyle: 'italic', fontSize: '18px' }}>
                  "I would like to see a section on the home page that has my favorite foods and a way to favorite them on the detail page or something similar"
                </div>
              </Col>
              <Col className="col-12 col-lg-5 d-flex align-items-center justify-content-center text-center">
                <img src={Home} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Row style={{ width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Refinement
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Row style={{ marginBottom: '60px', width: '100%' }}>
        <div style={{ background: 'rgb(232, 230, 230)', padding: '40px 40px 0' }}>
          <Container>
            <Row>
              <div className="grid">
                <img style={{ width: '100%' }} src={Refinement1} />
                <img style={{ width: '100%' }} src={Refinement2} />
                <img style={{ width: '100%' }} src={Refinement3} />
                <img style={{ width: '100%' }} src={Refinement4} />
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-6">
                <Row>
                  <div className="d-flex flex-row align-items-center">
                    <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>1</span>
                    <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    Refine and allow subscription changes
                  </span>
                  </div>
                  <ul style={{ marginLeft: '32px' }}>
                    <li style={{ fontSize: '18px', lineHeight: '32px' }}>Create subscription management page</li>
                    <li style={{ fontSize: '18px', lineHeight: '32px' }}>Allow users to change the subscription date</li>
                    <li style={{ fontSize: '18px', lineHeight: '32px' }}>Additional usability testing needed</li>
                  </ul>
                </Row>
                <Row>
                  <Col className="col-12 col-lg-12">
                    <div className="text-center">
                      <img src={NextSteps1} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-lg-6">
                <Row>
                  <div className="d-flex flex-row align-items-center">
                    <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>2</span>
                    <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    Include a review page before payment
                  </span>
                  </div>
                  <ul style={{ marginLeft: '32px' }}>
                    <li style={{ fontSize: '18px', lineHeight: '32px' }}>Add a review page before completing payment </li>
                    <li style={{ fontSize: '18px', lineHeight: '32px' }}>Include items, billing, payment, etc in overview</li>
                  </ul>
                </Row>
                <Row>
                  <Col className="col-12 col-lg-12">
                    <div className="text-center" style={{ display: 'flex', justifyContent: 'center' }}>
                      <img src={NextSteps2} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <div style={{ fontSize: '35px' }}>Next Steps</div>
            </Row>
            <Row>
              <Col className="col-12 col-lg-5">
                <div className="text-center">
                  <img style={{ marginTop: '-95px' }} src={Home} />
                  <img style={{ marginLeft: '-120px', marginTop: '95px' }} src={Search} />
                </div>
              </Col>
              <Col className="col-12 col-lg-7">
                <div className="d-flex flex-row align-items-center" style={{ marginBottom: '44px' }}>
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>1</span>
                  <span style={{ fontSize: '22px' }}>
                    Investigate feasibility around adding to cart directly from search page
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center" style={{ marginBottom: '44px' }}>
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>2</span>
                  <span style={{ fontSize: '22px' }}>
                    Experimentation with adding ability to favorite items and add to home page section
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center" style={{ marginBottom: '44px' }}>
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>3</span>
                  <span style={{ fontSize: '22px' }}>
                    Ongoing user research after suggested recommendations using high fidelity prototypes
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Row style={{ width: '100%'}}>
        <div style={{ background: 'black', height: '94px' }} />
      </Row>
    </div>
  )
};

export default GoogleUX;
