import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import GoogleUX from './pages/Projects/GoogleUX';
import TaskedUX from './pages/Projects/TaskedUX';
import TestCaseManagement from './pages/Projects/TestCaseManagement';

import './App.css';

function App() {
  const location = useLocation();

  return (
    <div>
      <header>
        <Navbar expand="md">
          <Container>
            <div></div>
            <Navbar.Toggle  className="align-self-end" aria-controls="responsive-navbar-nav" />
            <Navbar.Offcanvas id="responsive-navbar-nav">
              <Offcanvas.Header closeButton />
              <Offcanvas.Body>
                <Nav className="m-auto">
                  <Nav.Link as={NavLink} className="link" to="/">Home</Nav.Link>
                  <Nav.Link as={NavLink} className="link" to="/about">About</Nav.Link>
                  {/*<NavDropdown className="link" title="Projects" id="collasible-nav-dropdown">*/}
                  {/*  <NavDropdown.Item as={NavLink} className="link" to="/google-ux-research-project">*/}
                  {/*    Google UX Research Project*/}
                  {/*  </NavDropdown.Item>*/}
                  {/*  <NavDropdown.Item as={NavLink} className="link" to="/test-case-management-research">*/}
                  {/*    Test Case Management Research and Analysis*/}
                  {/*  </NavDropdown.Item>*/}
                  {/*  <NavDropdown.Item as={NavLink} className="link" to="/tasked-ux-research">*/}
                  {/*    Tasked UX Research*/}
                  {/*  </NavDropdown.Item>*/}
                  {/*</NavDropdown>*/}
                  <Nav.Link as={NavLink} className="link" to="/projects">Projects</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </header>
      <main>
        <TransitionGroup component={null}>
          <CSSTransition in={true} classNames="fade" key={location.key} timeout={700}>
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/google-ux-research" element={<GoogleUX />} />
              <Route path="/projects/tasked-ux-research" element={<TaskedUX />} />
              <Route path="/projects/test-case-management-research" element={<TestCaseManagement />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </main>
    </div>
  );
}

export default App;
