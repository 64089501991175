import React from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

import './index.css';

const Projects = () => {
  return (
    <Container className="page-container">
      <div className="project-grid">
        <div className="project-block mb-3">
          <Link className="project-title" to="/projects/google-ux-research">
            Google UX Design Project
          </Link>
          <div className="project-description">
            Project done during Google UX Design Course to determine usability of cat food ordering and delivering app - User Interviews, Usability Testing, Competitive Analysis, Affinity Mapping, Wireframes, Prototyping
          </div>
        </div>
        <div className="project-block mb-3">
          <Link className="project-title" to="/projects/test-case-management-research">
            Test Case Management Research and Analysis
          </Link>
          <div className="project-description">
            Research and analysis done on QA team to determine best TCM tool for the team - Survey, affinity mapping, decision matrix, comparative analysis
          </div>
        </div>
        <div className="project-block mb-3">
          <Link className="project-title" to="/projects/tasked-ux-research">
            Tasked UX Research
          </Link>
          <div className="project-description">
            Planned research for a productivity app my husband and I developed. Research test plan and Card Sorting completed
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Projects;
