import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const TaskedUX = () => {
  return (
    <div className="page-container">
      <Container>
        <Row className="text-start" style={{ marginBottom: '60px', width: '100%' }}>
          <div className="col-lg-1" />
          <div className="col-lg-10">
            <h1 style={{ fontSize: '56px', fontWeight: 'bold' }}>Tasked UX Research</h1>
            <Col className="col-lg-6" style={{ fontSize: '16px' }}>
              Closed Card Sorting, Usability Testing (TBD), Affinity Mapping (TBD)
            </Col>
          </div>
        </Row>
      </Container>
      <Row style={{ marginBottom: '60px', width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Research Background and Plan
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row style={{ marginBottom: '60px' }}>
          <div className="col-lg-1" />
          <Col className="col-12 col-lg-4">
            <h3 style={{ fontSize: '32px', fontWeight: 'bold' }}>Evaluative Research</h3>
            <div style={{ fontSize: '20px', lineHeight: '32px' }}>
              Card Sorting
            </div>
          </Col>
          <Col className="col-12 col-lg-5">
            <div style={{ fontSize: '18px', lineHeight: '32px', marginBottom: '24px' }}>
              A productivity app, called Tasked, has been developed and released to users. It allows users to select categories, and the app will generate a random task from that category to do. However, because the app was created rather quickly with limited resources, research wasn’t conducted before or during the design phase
            </div>
            <div style={{ fontSize: '18px', lineHeight: '32px' }}>
              We want to perform research, in this case, card sorting, to assess and validate current productivity categories and ensure they make sense to users. Additionally, we want to hold usability testing sessions for the version available in the app play store. We’re aiming to understand how users utilize the app and what improvements could be made to increase usage
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-6">
                <h3 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: 0 }}>
                  Card Sorting
                </h3>
                <h4>Research Methodology</h4>
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Unmoderated closed card sorting study</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Location: Remote US, web based tool</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Date: August 28th </li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>15-20 participants will go through the card sorting activity, and answer a series of question following the activity</li>
                </ul>
              </Col>
              <Col className="col-12 col-lg-5">
                <ul style={{ marginBottom: 0 }}>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Do the users understand what the category labels mean?</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Does the current category organization make sense as it is?</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Are there any tasks that don’t belong in a certain category?</li>
                  <li style={{ fontSize: '16px', lineHeight: '32px' }}>Do we need additional categories for misguided tasks?</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Row style={{ marginBottom: '60px', width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Findings and Insights
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row style={{ marginBottom: '36px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <div style={{ fontSize: '18px', lineHeight: '24px' }}>
                When the app is started initially, the user can select the productivity categories they want included. The app will assign tasks from each of the selected categories. We had 6 categories and and 52 productivity tasks already dispersed into their respective categories. However, we wanted to gain insight into whether our organization made sense to users, and if there is a better way to categorize them.
              </div>
            </Row>
            <Row style={{ marginTop: '24px' }}>
              <Col className="col-12 col-lg-6" style={{ marginBottom: '24px' }}>
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>1</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Many users mixed hobbies and self care activities with one another
                  </span>
                </div>
                <ul style={{ marginLeft: '48px' }}>
                  <li style={{ fontSize: '17px', lineHeight: '22px' }}>12 out of 20 users mentioned self care tasks were unclear as they could fall into multiple categories</li>
                </ul>
                <div style={{ fontSize: '17px', fontStyle: 'italic', marginLeft: '48px' }}>
                  "There were a couple that could go in different categories depending on context. One was “cook a new recipe”, which I filed under “hobbies” but could also go under “household” or maybe even “self care”
                </div>
              </Col>
              <Col className="col-12 col-lg-6"  style={{ marginBottom: '24px' }}>
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>2</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Likewise, academic and career categories led to confusion, with users divided amongst them
                  </span>
                </div>
                <ul style={{ marginLeft: '48px' }}>
                  <li style={{ fontSize: '17px', lineHeight: '22px' }}>5 out of 20 users specifically noted in their comments that many academic or career tasks overlap for them at the moment</li>
                </ul>
                <div style={{ fontSize: '17px', fontStyle: 'italic', marginLeft: '48px' }}>
                  "It was hard for me to pick academics vs career because I am an ESL teacher"
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-lg-6"  style={{ marginBottom: '24px' }}>
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>3</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    The household category was the most widely understood category
                  </span>
                </div>
                <div style={{ fontSize: '17px', marginLeft: '48px' }}>
                  72% of users were in agreement on the category with nearly all intended tasks at a 100% agreement
                </div>
              </Col>
              <Col className="col-12 col-lg-6" style={{ marginBottom: '24px' }}>
                <div className="d-flex align-items-center">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>4</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Self care seemed to be a catch all category - this category had the widest variety of tasks assigned to it
                  </span>
                </div>
                <ul style={{ marginLeft: '48px' }}>
                  <li style={{ fontSize: '17px', lineHeight: '22px' }}>5 out of 20 users stated this category name was unclear to them</li>
                </ul>
                <div style={{ fontSize: '17px', fontStyle: 'italic', marginLeft: '48px' }}>
                  "I would add a category like leisure, relaxation or entertainment"
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '30px' }}>
          <Col className="col-lg-2" />
          <Col className="col-12 col-lg-8">
            <ul>
              <li style={{ lineHeight: '34px', fontSize: '26px', color: 'rgb(94, 146, 97)', fontWeight: 'bold', marginBottom: '30px' }}>
                Because so many tasks were ambiguous, participants had a hard time organizing them into their respective categories
              </li>
              <li style={{ lineHeight: '34px', fontSize: '26px', color: 'rgb(94, 146, 97)', fontWeight: 'bold', marginBottom: '30px' }}>
                Participants have a different understanding of certain tasks as they pertain to them personally
              </li>
              <li style={{ lineHeight: '34px', fontSize: '26px', color: 'rgb(94, 146, 97)', fontWeight: 'bold', marginBottom: '30px' }}>
                Direct, widely understood tasks, such as those in household and exercise, made more sense to users
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Row style={{ marginBottom: '60px', width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Recommendations and Next Steps
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row style={{ marginBottom: '40px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row>
              <Col className="col-12 col-lg-6" style={{ marginBottom: '24px' }}>
                <div className="d-flex align-items-center mb-3">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>1</span>
                  <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    Review the copy content for the tasks
                  </span>
                </div>
                <div style={{ fontSize: '18px', marginLeft: '48px' }}>
                  Ensure the tasks are direct, with indication of what category they may fall under
                </div>
              </Col>
              <Col className="col-12 col-lg-6" style={{ marginBottom: '24px' }}>
                <div className="d-flex align-items-center mb-3">
                  <span className="ms-md-4 me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>2</span>
                  <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    Experiment with the category selection page
                  </span>
                </div>
                <div style={{ fontSize: '18px', marginLeft: '48px' }}>
                  Such as revealing all tasks under each category, or allowing the users to select individual tasks instead
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <hr style={{ margin: '0', borderWidth: '3px', }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '60px' }}>
          <Col className="col-lg-2" />
          <Col className="col-12 col-lg-8">
            <ul>
              <li style={{ fontSize: '26px', fontWeight: 'bold', lineHeight: '34px' }}>Recruit for and conduct usability testing for the application itself</li>
              <ul>
                <li style={{ fontSize: '18px', lineHeight: '32px' }}>Does the user understand how to assign a task to themselves?</li>
                <li style={{ fontSize: '18px', lineHeight: '32px' }}>How often would a user remove a task from their rotation?</li>
                <li style={{ fontSize: '18px', lineHeight: '32px' }}>Would users routinely create their own tasks to add to rotation?</li>
                <li style={{ fontSize: '18px', lineHeight: '32px' }}>How intuitive and easy is it to navigate through different pages of the application?</li>
              </ul>
            </ul>
          </Col>
        </Row>
      </Container>
      <Row style={{ width: '100%'}}>
        <div style={{ background: 'black', height: '94px' }} />
      </Row>
    </div>
  );
};

export default TaskedUX;
