import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const TestCaseManagement = () => {
  return (
    <div className="page-container">
      <Container>
        <Row className="text-start" style={{ marginBottom: '60px', width: '100%' }}>
          <div className="col-lg-1" />
          <div className="col-lg-10">
            <h1 style={{ fontSize: '56px', fontWeight: 'bold' }}>
              Test Case Management Tool Research and Analysis
            </h1>
            <Col className="col-lg-6" style={{ fontSize: '16px' }}>
              Survey, Affinity Diagram, Decision Matrix, Comparative Analysis
            </Col>
          </div>
        </Row>
      </Container>
      <Row style={{ marginBottom: '20px', width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Research Background
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <div style={{ fontSize: '20px', lineHeight: '1.8em', marginBottom: '28px' }}>
              The QE team at Calendly had been unhappy with their current tooling for test case management. I stepped in to assist with researching current pain points and analyzing potential tools for use.
            </div>
            <div style={{ fontSize: '20px', lineHeight: '1.8em', marginBottom: '28px' }}>
              We wanted to gather information from our team so we could make an informed decision that best represented the wants and needs for everyone.
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '28px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <Row className="d-flex align-items-center">
              <Col className="d-flex flex-column justify-content-center col-12 col-lg-6">
                <h3 style={{ fontWeight: 'bold' }}>Research Methodology</h3>
                <ul>
                  <li style={{ fontSize: '18px' }}>
                    Survey
                  </li>
                  <ul>
                    <li>10 participants</li>
                    <li>QE2 and QE3 level</li>
                    <li>US, Remote</li>
                    <li>Team members were asked a series of questions regarding their current and past experiences with TCM tools</li>
                  </ul>
                </ul>
              </Col>
              <Col style={{ fontSize: '18px' }} className="d-flex flex-column justify-content-center col-12 col-lg-6">
                <div style={{ marginBottom: '20px' }}>We wanted to find out the following:</div>
                <ul>
                  <li>What were the pain points with our current TCM tool?</li>
                  <li>What did they actually like about it?</li>
                  <li>What TCM tools did they have experience with using the past?</li>
                  <li>Why did they like their past TCM tools?</li>
                  <li>Why did they dislike them?</li>
                  <li>Were there any deal-breakers or must haves for the new TCM tool?</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Row style={{ width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Themes and Insights
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Row style={{ width: '100%' }}>
        <div style={{ background: 'rgb(232, 230, 230)', padding: '40px' }}>
          <Container>
            <Row>
              <Col className="col-lg-1" />
              <Col className="col-12 col-lg-8">
                <div style={{ fontSize: '18px', marginBottom: '28px' }}>After reaching out to the QE team and gathering responses from the survey, there were multiple themes identified based on current pain points, must haves and past experiences</div>
              </Col>
            </Row>
            <Row>
              <div className="col-lg-1" />
              <div className="col-12 col-lg-8">
                <div className="d-flex align-items-center mb-4">
                  <span className="me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>1</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Product and feature functionality within qTest was an issue for all of our QE members. The most common functional issue was around the difficulty of copying or re-using test cases
                  </span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <span className="me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>2</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Technical capability - including APIs, automation and integrations were very important must haves for the group
                  </span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <span className="me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>3</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Ease of use was a common topic - many members of the QE team mentioned that the user interface was poor and non intuitive, but they liked qTest's organization and customization
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="me-2" style={{ color: 'rgb(94, 146, 97)', fontSize: '55px', fontWeight: 'bold' }}>4</span>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Performance issues were brought up, but only a must have for a small portion of the QE team
                  </span>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Row style={{ marginBottom: '60px', width: '100%'}}>
        <div style={{ background: 'black', padding: '20px' }}>
          <Container>
            <Row>
              <div className="col-lg-1" />
              <div className="col-lg-10">
                <h2 style={{ color: 'white', fontSize: '45px', fontWeight: 'bold', margin: '0' }}>
                  Tool Comparison
                </h2>
              </div>
            </Row>
          </Container>
        </div>
      </Row>
      <Container>
        <Row>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <div style={{ fontSize: '18px', marginBottom: '48px' }}>Six test case management tools were analyzed using a decision matrix based off of the gathered insights above:</div>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))', justifyContent: 'center', marginBottom: '48px' }}>
              <div style={{ fontWeight: 'bold', fontSize: '19px', textAlign: 'center', padding: '62px 0', backgroundColor: 'rgb(232, 230, 230)'}}>Functionality</div>
              <div style={{ fontWeight: 'bold', fontSize: '19px', textAlign: 'center', padding: '62px 0', backgroundColor: 'rgb(232, 230, 230)'}}>Performance</div>
              <div style={{ fontWeight: 'bold', fontSize: '19px', textAlign: 'center', padding: '62px 0', backgroundColor: 'rgb(232, 230, 230)'}}>Usability</div>
              <div style={{ fontWeight: 'bold', fontSize: '19px', textAlign: 'center', padding: '62px 0', backgroundColor: 'rgb(232, 230, 230)'}}>Integrations</div>
              <div style={{ fontWeight: 'bold', fontSize: '19px', textAlign: 'center', padding: '62px 0', backgroundColor: 'rgb(232, 230, 230)'}}>Technical Capabilities</div>
              <div style={{ fontWeight: 'bold', fontSize: '19px', textAlign: 'center', padding: '62px 0', backgroundColor: 'rgb(232, 230, 230)'}}>Cost</div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '48px' }}>
          <Col className="col-lg-1" />
          <Col className="col-12 col-lg-10">
            <div style={{ fontSize: '18px', marginBottom: '28px' }}>This research and insight allowed us to focus our efforts in on areas that mattered and successfully compare software tools in an efficient and timely matter. In the end, we came to a quick decision!</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TestCaseManagement;
